<script lang="ts">
    import { Spinner } from '../';
    import { twMerge } from 'tailwind-merge';
    import type { ButtonType } from '../types';

    type ButtonColor = keyof typeof colorClasses;

    export let size: 'xxs' | 'xs' | 'base' = 'base';
    export let color: ButtonColor = 'dark';
    export let href: string | undefined = undefined;
    export let type: ButtonType = 'button';
    export let loading = false;

    const sizeClasses = {
        xs: 'text-xs font-medium leading-none',
        base: 'text-sm font-medium leading-none',
    };

    const colorClasses = {
        dark: 'text-gray-900 disabled:text-gray-300',
        red: 'text-red-700 disabled:text-red-400',
    };

    const baseClasses = ['inline-flex justify-center items-center gap-1 text-center', 'bg-transparent outline-none focus:outline-none border-none', 'disabled:cursor-not-allowed'];

    const spinnerClasses = {
        xs: 'w-[1.125rem] h-[1.125rem]',
        base: 'w-6 h-6',
    };

    $: buttonClass = twMerge(...baseClasses, sizeClasses[size], colorClasses[color], $$props.class);
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<svelte:element
    this={href ? 'a' : 'button'}
    type={href ? undefined : type}
    {href}
    {...$$restProps}
    disabled={$$props.disabled || loading}
    class={buttonClass}
    on:click
    on:change
    on:keydown
    on:keyup
    on:touchstart
    on:touchend
    on:touchcancel
    on:mouseenter
    on:mouseleave
>
    {#if loading}
        <Spinner class={spinnerClasses[size]} />
    {:else}
        <slot />
    {/if}
</svelte:element>

<!--
  @component
  ## Features
  [Go to Button](https://flowbite-svelte.com/docs/components/button)
  - Setup
  - Icon button with clear background
  - Button with link
  - Button pills
  - Gradient monochrome
  - Gradient duotone
  - Gradient outline
  - Colored shadows
  - Button sizes
  
  - Loader
  - Disabled  
  ## Props
  @prop size: 'xxs' | 'xs' | 'base' = 'base';
  @prop href: string | undefined = undefined;
  @prop type: ButtonType = 'button';
  @prop loading: boolean = false;
  ## Event
  - on:click
  - on:change
  - on:keydown
  - on:keyup
  - on:touchstart
  - on:touchend
  - on:touchcancel
  - on:mouseenter
  - on:mouseleave
  ## Example
  ```
  <script>
    import { Button } from '@allibee/components/basic';
  </script>

  <Button>Default</Button>
  <Button color="alternative">Alternative</Button>
  <Button color="dark">Dark</Button>
  <Button color="light">Light</Button>  
  ```
-->
